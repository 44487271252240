<template>
  <div class="header-page">
    <div class="logo">
      <img v-if="info.hotelLogoUrl" class="logo-img" :src="info.hotelLogoUrl" alt="" />
      <img v-else class="logo-img" src="../../assets/images/logo.png" alt="" />
    </div>
    <div class="nave">
      <div class="title">{{ hotel.name }}</div>
      <el-dropdown class="userInfo" @command="fnCommand" :hide-on-click="false" trigger="click">
        <div class="el-dropdown-link">
          <div style="text-align: center">
            <div style="color: #fff">{{ info.loginUsername }}</div>
            <div style="color: #fff">{{ info.phoneNumber }}</div>
          </div>
          <div class="avatar-uploader">
            <img v-if="info.photoUrl" :src="imageUrl + info.photoUrl" class="avatar" />
            <img v-else src="../../assets/images/logo.png" />
          </div>

          <!-- <el-upload class="avatar-uploader" name="files" :data="uploadData" :headers="uploadHeaders" :action="action" :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
            <img v-if="info.photoUrl" :src="imageUrl + info.photoUrl" class="avatar">
            <img v-else src="../../assets/images/logo.png">
          </el-upload> -->
        </div>
        <el-dropdown-menu slot="dropdown">
          <!--          <el-dropdown-item>个人中心</el-dropdown-item>-->
          <!-- <el-dropdown-item>设置</el-dropdown-item> -->
          <el-dropdown-item>
            <el-dropdown placement="right-start" class="personalization">
              <span class="el-dropdown-link personalization">
                切换酒店
                <i class="icon iconfont icongexinghuashezhi"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for="item in orgs" :index="item.id" :key="item.id" @click.native="switchHotel(item)">{{ item.label }}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </el-dropdown-item>
          <el-dropdown-item command="changelog">更换头像</el-dropdown-item>
          <el-dropdown-item command="logout">注销登录</el-dropdown-item>
          <el-dropdown-item v-if="info.loginUsername=='鹿小乙管理员'" command="inport">导入</el-dropdown-item>
          <el-dropdown-item>版本号：1.0.1</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>

      <el-dialog :close-on-click-modal="false" title="点击更换头像" :visible.sync="headDialogVisible" width="400px" center>
        <el-form label-width="20px">
          <el-form-item label="">
            <el-upload
              class="avatar-uploader"
              name="files"
              :data="uploadData"
              :headers="uploadHeaders"
              :action="action"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
            >
              <img style="width:300px;height:300px" v-if="info.photoUrl" :src="imageUrl + info.photoUrl" class="avatar" />
              <img style="width:300px;height:300px" v-else src="../../assets/images/logo.png" />
            </el-upload>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <!-- <el-button @click="headDialogVisible = false">取 消</el-button> -->
          <el-button type="primary" @click="headFile">确定</el-button>
        </span>
      </el-dialog>
      <el-dialog :close-on-click-modal="false" title="导入" :visible.sync="DialogVisible" width="600px" center>
        <el-form label-width="120px">
          <el-form-item label="导入类型">
            <el-select style="width: 350px" v-model="ruleForm.type" placeholder="选择酒店" filterable>
              <el-option v-for="item in inputTypes" :key="item.value" :label="item.name" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="ruleForm.type==1" label="导入企业">
            <el-input style="width:400px;margin-left:10px" maxlength="50" placeholder="输入酒店标识" clearable v-model="ruleForm.applicationName"></el-input>
          </el-form-item>
          <el-form-item v-if="ruleForm.type!==1" label="选择企业">
            <el-select style="width: 350px" v-model="ruleForm.hotelId" placeholder="选择酒店" filterable>
              <el-option v-for="item in allHotels" :key="item.applicationName" :label="item.name" :value="item.applicationName">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="">
          </el-form-item>
          <el-form-item label="">
          </el-form-item>
          <el-form-item label="">
          </el-form-item>
          <el-form-item label="">
          </el-form-item>
        </el-form>
        <span slot="footer" v-loading="FormLoading" class="dialog-footer">
          <el-button @click="DialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="goInput">提 交</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { uploadFile, imageUrl } from '@/utils/api'
import { initMenu } from '@/utils/menus'
import router from '@/router'
import store from '@/store'
import { post, socketUrl, wss } from '../../utils/api'
let token = ''
if (window.localStorage.getItem('token')) {
  token = window.localStorage.getItem('token').toString()
}
export default {
  data() {
    return {
      headDialogVisible: false,
      imageUrl,
      info: {},
      photoUrl: 'https://cdn.pixabay.com/photo/2021/10/01/03/46/goat-6671275__340.jpg',
      action: uploadFile,
      uploadHeaders: {
        'Access-Control-Allow-Origin': '*',
        accessToken: 'Bearer ' + token
      },
      uploadData: {
        num: 1
      },
      hotel: [],
      orgs: [],
      hotels: [],
      DialogVisible: false,
      FormLoading: false,
      ruleForm: { type: 2, hotelId: '-1', applicationName: '-1' },
      allHotels: [],
      inputTypes: [
        { value: 1, name: '导入酒店和角色' },
        { value: 2, name: '导入酒店用户' },
        { value: 3, name: '导入酒店客户' },
        { value: 4, name: '导入酒店零点--客户来源' },
        { value: 5, name: '导入酒店零点--餐区管理' },
        { value: 6, name: '导入酒店零点--人数区间' },
        { value: 7, name: '导入酒店零点--雅间' },
        { value: 8, name: '导入酒店零点--就餐时间设置' },
        { value: 9, name: '导入酒店零点--订单' },
        { value: 10, name: '导入酒店短信充值记录' },
        { value: 12, name:'导入宴会--餐标管理'},
        { value: 13, name:'导入宴会--收费服务'},
        { value: 14, name:'导入宴会--支付项目'},
        { value: 15, name:'导入宴会--支付方式'},
        { value: 16, name:'导入宴会--担保人'},
        { value: 17, name:'导入宴会--宴会类型'},
        { value: 18, name:'导入宴会--宴会厅'}
      ]
    }
  },
  mounted() {
    // 调用soket
    this.connectSrv()
  },
  created() {
    // 加载酒店信息
    // console.log('酒店：' + JSON.stringify(this.$store.state.hotels))
    if (this.$store.state.hotellist && this.$store.state.hotellist.length > 0) {
      this.hotel = this.$store.state.hotellist[0]
      this.hotels = this.$store.state.hotels

      // console.log('酒店：' + JSON.stringify(this.hotels))

      this.hotels.forEach(o => {
        this.orgs.push({
          id: o.id,
          label: o.name,
          value: o.id,
          applicationName: o.code,
          orgBizId: o.id
        })
      })
    } else {
      // -- 没有默认酒店，跳转到登录页面
      this.$router.push('/login')
      //   this.hotel = {
      //     name: '总管理平台'
      //   }
    }
    if (window.localStorage.getItem('info')) {
      this.info = JSON.parse(window.localStorage.getItem('info'))
    }
    if (this.info.loginUsername == '鹿小乙管理员') {
      this.bindHotels()
    }
  },
  methods: {
    // 1. 下拉菜单事件
    fnCommand(command) {
      switch (command) {
        // 1.1 退出登录
        case 'logout':
          this.postRequest('sys/user/logout')
          // 重置vuex中的数据
          this.$store.commit('clearVUEX')
          const loginInfo = window.localStorage.getItem('loginInfo')
          window.localStorage.clear()
          window.localStorage.setItem('loginInfo', loginInfo)
          this.$router.replace({ path: '/' })
          break
        // 1.2 退出
        case 'changelog':
          this.headDialogVisible = true
          break
        case 'inport':
          this.DialogVisible = true
          this.ruleForm = { type: 2, hotelId: '-1', applicationName: '-1' }
          break
      }
    },
    // 2. 切换酒店
    switchHotel(item) {
      //   console.log('item:' + JSON.stringify(item))
      //   console.log('hotel:' + JSON.stringify(this.hotel))
      // 判断当前酒店的bizId是否相同
      if (this.hotel.id === item.id) {
        // 当前酒店与切换酒店相同
        return false
      }
      // 当前的hotel 切换到选中的hotel
      //  console.log('this is hotels ==>' + JSON.stringify(this.hotels))
      this.hotels.forEach(o => {
        if (item.id === o.id) {
          this.hotel = o
        }
      })

      // --重新登录
      this.postRequest(
        'sys/user/login',
        this.qs.stringify({
          username: this.hotel.userName + '&#' + this.hotel.phone,
          password: this.hotel.phone,
          saveLogin: 1
        })
      ).then(res => {
        // --
        if (res.code == 200) {
          // 1. 保存token
          window.localStorage.setItem('token', res.result)
          // 2. 重新获取用户信息
          this.getRequest('sys/user/info', {}).then(response => {
            // console.log('切换结果：' + JSON.stringify(response.data))
            if (response.code == 200) {
              //
              // 3. 保存当前用户id
              window.localStorage.setItem('bizId', response.data.id)
              // 4. 存储权限（判断酒店类型）
              window.localStorage.setItem('accountType', response.data.accountType)
              // 5. 存储酒店标识：applicationName
              window.localStorage.setItem('applicationName', response.data.orgs[0].applicationName)
              window.localStorage.setItem('hotelBizId', response.data.orgs[0].id)
              // 6. 当前用户所有角色 roles
              if (response.data.roles) {
                window.localStorage.setItem('roles', JSON.stringify(response.data.roles))
              }
              // 7. 用户权限，验证点击事件
              window.localStorage.setItem('authority', response.data.authority)

              // 8. 存储酒店信息 --orgs 另外放一个list中 这两个list不能冲突
              if (response.data.orgs) {
                // 8.1 存储第一个元素。默认第一个酒店
                var arr = []
                arr.push(response.data.orgs[0])
                window.localStorage.setItem('orgs', JSON.stringify(arr))
                this.$store.commit('setHotelList', arr)
                // 8.2 保存所有酒店，用户切换酒店时使用
                window.localStorage.setItem('orgList', JSON.stringify(response.data.orgs))
                this.$store.commit('setHotels', response.data.orgs)
              }

              const info = {
                name: response.data.name || '管理员',
                loginUsername: response.data.username || 'admin',
                phoneNumber: response.data.phone || '',
                hotelLogoUrl: response.data.logoUrl || '',
                photoUrl: response.data.photoUrl || ''
              }
              this.info = info
              window.localStorage.setItem('info', JSON.stringify(info))

              // 8. 清空权限,重新加载菜单
              store.state.routes = []
              initMenu(router, store)

              // 9.刷新页面
              this.handleAllClose()
            }
          })
        } else {
          this.$message.error(res.msg)
          return false
        }
      })
    },
    handleAllClose() {
      this.$router.replace({ path: '/home' })
      this.$store.commit('clearAllClose')
    },
    // 图片上传前
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    },
    // 图片上传后
    handleAvatarSuccess(res, file) {
      //   console.log('图片上传结果')
      if (res.code !== 200) {
        return false
      } else {
        this.info.photoUrl = res.data
      }
    },
    // 提交图片
    headFile() {
      this.post('sys/user/changePicture', {
        photoUrl: this.info.photoUrl
      }).then(res => {
        // console.log('添加结果：' + JSON.stringify(res))
        if (res.code == 200) {
          const info = JSON.parse(window.localStorage.getItem('info'))
          info.photoUrl = this.info.photoUrl
          window.localStorage.setItem('info', JSON.stringify(info))
          this.$message({
            duration: 10000,
            type: 'success',
            message: '设置成功'
          })
          this.headDialogVisible = false
        }
      })
    },
    // ----------------------webScoket 开始 -------------------------------------
    // 1. 开启连接
    connectSrv() {
      //   const ws = 'http://localhost:7710/lxy/ws'
      const ws = socketUrl
      var headers = {}
      this.connetWM(ws, headers, this.onConnected, this.onFailed)
    },
    // 2. 执行连接
    onConnected(frame) {
      // 1. 订阅广播系统通知
      this.$stompClient.subscribe('/topic/subscribe', this.responseCallback, this.onFailed)
      // 2.1 获取用户id
      var userId = window.localStorage.getItem('bizId')
      // 2.2 订阅点对点 通过用户id指定用户
      this.$stompClient.subscribe('/user/' + userId + '/queue/subscribe', this.responseCallback, this.onFailed)
    },
    // 3. 连接失败
    onFailed(frame) {
      // console.log('连接ws失败： ' + JSON.stringify(frame))
    },
    // 4. 接收消息
    responseCallback(frame) {
      if (frame.body) {
        var messageBody = JSON.parse(frame.body)
        // console.log('收到消息：' + frame.body)
        // 根据消息内容，判定是刷新零点还是宴会
        // 刷新零点
        if (messageBody.type == 'dinner') {
          this.$store.dispatch('setNoticeCount', {
            noticeDinner: this.guid()
          })
        }
        // 刷新宴会
        if (messageBody.type == 'banquet') {
          this.$store.dispatch('setNoticeBanquetCount', {
            noticeDinner: this.guid()
          })
        }
      }
    },
    // 5. 关闭socket
    disconnect() {
      this.disconnetWM()
    },
    // ----------------------webScoket 结束 -------------------------------------
    guid() {
      return S4() + S4() + '-' + S4() + '-' + S4() + '-' + S4() + '-' + S4() + S4() + S4()
      function S4() {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
      }
    },
    bindHotels() {
      this.getRequest('sys/InptuController/allHotel', {}).then(res => {
        if (res.code == 200) {
          var list = res.data
          var body = {
            applicationName: '-1',
            name: '全部'
          }
          list.push(body)
          this.allHotels = list
        }
      })
    },
    goInput() {
      var postUrl = ''
      switch (this.ruleForm.type) {
        // 1. 导入酒店和角色
        case 1:
          postUrl = 'sys/InptuController/inHotel'
          break
        // 2. 导入酒店用户
        case 2:
          postUrl = 'sys/InptuController/inHotelUser'
          break
        // 3. 导入酒店客户
        case 3:
          postUrl = 'sys/InptuController/inHotelCustomer'
          break
        // 4. 导入酒店零点--客户来源
        case 4:
          postUrl = 'sys/InptuController/inCustomerSource'
          break
        // 5. 导入酒店零点--餐区管理
        case 5:
          postUrl = 'sys/InptuController/inDinnerZone'
          break
        // 6. 导入酒店零点--人数区间
        case 6:
          postUrl = 'sys/InptuController/inDinnerRange'
          break
        // 7. 导入酒店零点--雅间
        case 7:
          postUrl = 'sys/InptuController/inDinnerRoom'
          break
        // 8. 导入酒店零点--就餐时间设置
        case 8:
          postUrl = 'sys/InptuController/inTimeSetting'
          break
        // 9. 导入酒店零点--订单
        case 9:
          postUrl = 'sys/InptuController/inDinner'
          break
        // 10. 导入酒店短信充值记录
        case 10:
          postUrl = 'sys/InptuController/inMessageRecord'
          break
        // 12 导入宴会--餐标管理
        case 12:
          postUrl = 'sys/InptuController/inMealStandard'
          break
        // 13 导入宴会--收费服务
        case 13:
          postUrl = 'sys/InptuController/inProviderService'
          break
        // 14 导入宴会--支付项目
        case 14:
          postUrl = 'sys/InptuController/inPayCategory'
          break
        // 15 导入宴会--支付方式
        case 15:
          postUrl = 'sys/InptuController/inPayMode'
          break
        // 16 导入宴会--担保人
        case 16:
          postUrl = 'sys/InptuController/inCustomerGuarantor'
          break
        // 17 导入宴会---宴会类型
        case 17:
          postUrl = 'sys/InptuController/inDataCategory'
        // 18 导入宴会---宴会厅
        case 18:
          postUrl = 'sys/InptuController/inHall'
      }
      this.FormLoading = true
      this.post(postUrl, this.ruleForm)
        .then(res => {
          this.FormLoading = false
          // console.log('查询酒店结果：' + JSON.stringify(res))
          if (res.code == 200) {
            this.$message({
              duration: 10000,
              type: 'success',
              message: '导入成功'
            })
          } else {
            this.$notify({
              title: '提示',
              message: res.msg,
              type: 'error',
              duration: 2000
            })
          }
        })
        .catch(() => {
          this.FormLoading = false
        })
    }
    //
    // { value: 1, name: '导入酒店和角色' },
    //     { value: 2, name: '导入酒店用户' },
    //     { value: 3, name: '导入酒店客户' },
    //     { value: 4, name: '导入酒店零点--客户来源' },
    //     { value: 5, name: '导入酒店零点--餐区管理' },
    //     { value: 6, name: '导入酒店零点--人数区间' },
    //     { value: 7, name: '导入酒店零点--雅间' },
    //     { value: 8, name: '导入酒店零点--就餐时间设置' },
    //     { value: 9, name: '导入酒店零点--订单' },
    //     { value: 10, name: '导入酒店短信充值记录' }
  },
  stompClient: {
    monitorIntervalTime: 100,
    stompReconnect: true,
    timeout(orgCmd) {}
  }
}
</script>

<style lang="scss" scoped>
@import 'src/assets/css/public';

.header-page {
  width: 100%;
  height: 60px;
  background-color: $theme-bg-color;
  display: flex;
  //justify-content: space-between;
  box-sizing: border-box;
  color: #fff;

  .logo {
    width: 100px;
    height: 60px;
    overflow: hidden;
    position: relative;

    .logo-img {
      height: 40px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
    }
  }

  .nave {
    width: 100%;
    height: 60px;
    box-sizing: border-box;
    padding-right: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      font-size: 20px;
      //font-family: 华文楷体;
    }

    .userInfo {
      cursor: pointer;

      .el-dropdown-link {
        display: flex;
        align-items: center;
      }

      .el-dropdown-link img {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        margin-left: 10px;
        //border: 2px solid #fff;
        box-shadow: 1px 1px 5px #ccc;
        -moz-user-select: -moz-none;
        -khtml-user-select: none;
        -webkit-user-select: none;
        -o-user-select: none;
        user-select: none;
        pointer-events: none;
      }
    }
  }
}
</style>
