import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
const { formatDate } = require('../utils/date')

export default new Vuex.Store({
  state: {
    noticeDinner: '', // 零点通知
    noticeBanquet: '', // 宴会通知
    banquetReservation: {}, // 宴会预订数据
    zeropointReservation: {}, // 零点预订数据
    datevalue: formatDate(), // 当前时间
    hotellist: [], // 酒店数据
    hotels: [], // 酒店数据
    ballroomList: [], // 宴会厅数据
    positionAuthority: {}, // 职位权限设置
    routes: [], // 路由菜单
    shortmessage: null, // 短信添加模版
    messageRecharge: {}, // 短信充值设置--》跳转短信充值页面，传参
    missionPlan: {}, // 任务计划详情
    activitylist: {}, // 活动列表详情
    level: 2,
    // 缓存组件页面
    catch_components: [],
    // 当前选中的菜单 - 默认选择首页
    activePath: '主页',
    // 菜单项 - 默认包含首页
    tabList: [{ path: '/home', name: '主页', bizId: '主页' }]
  },

  // 改变state数据
  mutations: {
    // 刷新零点销控
    SET_ID: (state, view) => {
      if (state.noticeDinner !== view) {
        state.noticeDinner = view
      }
    },
    // 刷新宴会销控
    SET_BANQUET: (state, view) => {
      if (state.noticeBanquet !== view) {
        state.noticeBanquet = view
      }
    },
    // 清空vuex数据
    clearAllClose(state) {
      state.catch_components = []
      state.activePath = '主页'
      state.tabList = [{ path: '/home', name: '主页', bizId: '主页' }]
    },
    // 清空vuex数据
    clearVUEX(state) {
      state.catch_components = []
      state.hotellist = []
      state.hotels = []
      state.ballroomList = []
      state.activePath = '主页'
      state.tabList = [{ path: '/home', name: '主页', bizId: '主页' }]
      state.routes = []
    },
    initRoutes(state, data) {
      state.routes = data
    },
    setBanquetReservation(state, value) {
      state.banquetReservation = value
    },
    setZeropointReservation(state, value) {
      state.zeropointReservation = value
    },
    setHotelList(state, value) {
      state.hotellist = value
    },
    setHotels(state, value) {
      state.hotels = value
    },
    setBallroomList(state, value) {
      state.ballroomList = value
    },
    setPositionAuthority(state, value) {
      state.positionAuthority = value
    },
    setShortmessage(state, value) {
      state.shortmessage = value
    },
    setMessageRecharge(state, value) {
      state.messageRecharge = value
    },
    setMissionPlan(state, value) {
      state.missionPlan = value
    },
    setActivitylist(state, value) {
      state.activitylist = value
    },
    // 跳转页面执行
    selectMenu(state, submenu) {
      // 首页就是 wellcome   也就是 home
      if (submenu.bizId === '主页') {
        submenu.path = '/home'
        submenu.name = '主页'
        submenu.bizId = '主页'
      }
      // 当前选中菜单
      const activePath = submenu.bizId
      // 历史已选中菜单列表
      const oldTabList = state.tabList
      // 将菜单信息添加到tablist - 添加时判断是否已有该标签
      const result = oldTabList.some(item => {
        if (item.bizId === activePath) {
          return true
        }
      })
      // 如果不包含该对象，则添加
      if (!result) {
        oldTabList.push({
          path: submenu.path,
          name: submenu.name,
          bizId: submenu.path
        })
      }
      // 重新赋值
      state.activePath = activePath
      state.tabList = oldTabList
    },
    // 添加keepalive缓存
    addKeepAliveCache(state, val) {
      // 如果是首页不缓存
      if (val === '主页') {
        return
      }
      // 添加时判断，如果该组件已存在，则不添加
      if (state.catch_components.indexOf(val) === -1) {
        // 不存在，缓存页面
        state.catch_components.push(val)
      }
    },
    // 删除keepalive缓存
    removeKeepAliveCache(state, val) {
      const cache = state.catch_components
      for (let i = 0; i < cache.length; i++) {
        if (cache[i] === val) {
          cache.splice(i, 1)
        }
      }
      state.catch_components = cache
    },
    // 关闭菜单
    closeTab(state, val) {
      // 重新赋值
      state.activePath = val.activePath
      state.tabList = val.tabList
    },
    // 点击标签选择菜单
    changeMenu(state, val) {
      state.activePath = val
    }
  },

  actions: {
    setNoticeCount({ commit }, { noticeDinner }) {
      //  console.log('进入：'+noticeDinner)
      return new Promise(resolve => {
        commit('SET_ID', noticeDinner)
        resolve()
      })
    },
    setNoticeBanquetCount({ commit }, { noticeBanquet }) {
      return new Promise(resolve => {
        commit('SET_BANQUET', noticeBanquet)
        resolve()
      })
    }
  },

  getters: {
    datevalueter: state => {
      return state.datevalue.substr(0, 7)
    }
  }
})
